import * as React from "react";
import { useState } from "react";
import { navigate } from "gatsby";

import { Helmet } from "react-helmet";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faVectorSquare,
  faFilm,
  faCode,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import FsLightbox from "fslightbox-react";

import projects from "../projects";

// markup
const OpenedProjectPage = (props) => {
  const [lightboxIndex, setLightboxIndex] = useState(undefined);
  const [lightboxOpenedCount, setLightboxOpenedCount] = useState(0);

  const path = props.location;

  const openedProject = projects.find(
    (project) => project.key === path.search.substring(6)
  );

  if (openedProject === undefined) {
    if (typeof window !== "undefined") {
      navigate("/404/");
    } else {
      return null;
    }
  }

  let projectIcon = faBorderAll;

  if (openedProject.category === "Design") {
    projectIcon = faVectorSquare;
  } else if (openedProject.category === "Filmmaking") {
    projectIcon = faFilm;
  } else if (openedProject.category === "Web development") {
    projectIcon = faCode;
  } else {
    projectIcon = faBorderAll;
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Jan Duras - Portfolio</title>

        <meta name="theme-color" content="#FFC132"></meta>
      </Helmet>

      <div className="container p-8 sm:p-16 md:p-24 mx-auto text-center sm:text-left" data-cy="projectContent">
        <div className="pb-6 px-8 flex justify-center sm:justify-between flex-wrap lg:flex-nowrap">
          <div className="flex items-center flex-wrap lg:flex-nowrap justify-center sm:justify-between">
            <div className="p-5 mr-3 bg-blue bg-opacity-10 rounded-full">
              <FontAwesomeIcon
                className="text-3xl text-blue"
                icon={projectIcon}
              />
            </div>
            <h2 className="text-dark font-semibold text-4xl sm:text-5xl mt-4 lg:mt-0">
              {openedProject.name}
            </h2>
          </div>
          <button
            onClick={() => navigate("/")}
            type="button"
            className="hidden lg:block transition-all px-5 text-dark text-opacity-75 hover:text-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
          >
            <FontAwesomeIcon className="text-3xl" icon={faTimes} />
          </button>
          <button
            onClick={() => navigate("/")}
            type="button"
            className="lg:hidden transition-all px-3 py-2 mt-10 text-dark text-opacity-75 rounded-md font-bold bg-grey text-lg hover:text-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue"
            data-cy="projectClose"
          >
            <FontAwesomeIcon icon={faTimes} /> Return home
          </button>
        </div>

        <img
          alt={openedProject.name}
          className="mt-5 w-full border border-grey rounded-t-lg"
          src={openedProject.src}
        />

        <div className="p-12 bg-grey bg-opacity-50 rounded-b-lg">
          <span className="px-2 py-1 mr-2 text-light rounded-md font-medium bg-blue">
            <FontAwesomeIcon icon={projectIcon} /> {openedProject.category}
          </span>
          <span className="text-dark text-opacity-75 text-lg font-medium mr-3">
            From {openedProject.date}
          </span>

          <p className="font-semibold text-2xl text-dark mt-8 mb-2">
            {openedProject.content}
          </p>

          <a
            className="transition-all font-semibold text-2xl text-blue hover:text-darkBlue"
            href={openedProject.linkUrl}
          >
            {openedProject.linkText}
          </a>

          <div className="mt-7">
            <h3 className="text-dark text-opacity-75 font-bold text-xl mb-1">
              Used skills
            </h3>
            <div className="flex flex-wrap justify-center sm:justify-start">
              {openedProject.skills.map((skill) => (
                <span className="text-dark text-sm text-opacity-70 font-bold rounded bg-dark bg-opacity-10 px-2 mr-1 mt-1">
                  {skill}
                </span>
              ))}
            </div>
          </div>

          {openedProject.work.length > 0 ? (
            <div className="mt-7 w-full">
              <h3 className="text-dark text-opacity-75 font-bold text-xl mb-1">
                Work examples
              </h3>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry gutter={12}>
                  {openedProject.work.map((work, index) => (
                    <img
                      className="rounded mt-1"
                      key={openedProject.key}
                      src={work}
                      style={{
                        width: "100%",
                        display: "block",
                        cursor: "pointer",
                      }}
                      alt={openedProject.name}
                      onClick={() => {
                        setLightboxIndex(index);
                        setLightboxOpenedCount((count) => count + 1);
                      }}
                    />
                  ))}
                </Masonry>
              </ResponsiveMasonry>

              <FsLightbox
                toggler={lightboxOpenedCount}
                sources={openedProject.work}
                sourceIndex={lightboxIndex}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OpenedProjectPage;
